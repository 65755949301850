<template>
  <div>
    <van-list v-model="loading" :finished="finished" @load="onLoad">
      <van-row type="flex" justify="center" v-for="(item,index) in list" :key="index">
        <van-col class="details" span="23">
          尊敬的
          <span class="details-red">{{item.name}}</span>居士你好，您刚才预约了
          <span class="details-red">{{item.specname}}</span>
          您的手机号为
          <span class="details-red">{{item.phone}}</span>
          请核对手机号
          感恩您对大珠山石门的支持,阿弥陀佛。
          如有问题请及时联系客堂：
          <span
            class="details-blue"
          >0532-84123457</span>
        </van-col>
      </van-row>
    </van-list>
    <!-- <div>底部地基</div> -->
    <div class="wrap-bottom"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPage: 1,
      list: [],
      loading: false,
      finished: false,
    };
  },
  created() {
    this.initialization();
  },
  methods: {
    onLoad() {
      this.getdetails();
      this.currentPage++;
    },
    initialization() {
      this.list = [];
      this.currentPage = 1;
      this.loading = true; //下拉加载中
      this.finished = false; //下拉结束
      if (this.loading) {
        this.onLoad();
      }
    },
    change() {
      this.getdetails();
    },
    getdetails() {
      this.$api.center.getregister(this.currentPage).then((res) => {
        if (res.data.data.list.length == 0) {
          this.finished = true;
        } else {
          this.list.push(...res.data.data.list);
          console.log(this.list);
        }
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>